import { signIn, useSession } from "next-auth/client";
import { useRouter } from "next/router";

import Spinner from "components/Spinner";

export default function Login() {
  const [session, loading] = useSession();

  const router = useRouter();

  if (loading) {
    return <Spinner />;
  }

  if (session) {
    router.push("/");
    return <Spinner />;
  }

  signIn("tx-data-platform");
  return null;
}
